<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">Permission</h2>
						<div class="breadcrumb-wrapper">
							<ol class="breadcrumb">
								<li class="breadcrumb-item active">
									<span aria-current="location"> List</span>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Table Container Card -->
		<b-card no-body>
			<div class="px-5" v-if="roles && roles.length > 0">
				<app-collapse
					accordion
					:type="collapseType"
					v-for="modules in allModules"
					:key="modules.id"
					v-model="modules_id"
				>
					<app-collapse-item :title="modules.module_name" :value="modules.id" class="collapse-card">
						<b-tabs
							vertical
							v-model="selectedRoleTabIndex"
							nav-wrapper-class="nav-vertical p-0 w-25 nav-role left-navigation"
						>
							<b-tab v-for="role in roles" :key="role.id" :title="role.name">
								<!-- Table Container Card -->
								<b-card no-body>
									<div class="table-header">
										<h4 class="header-title">Permission</h4>

										<div class="permission-view row">
											<div
												v-for="permission in modules.permission"
												:key="permission.id"
												class="custom-control custom-checkbox d-flex col-md-3 mb-1"
											>
												<input
													type="checkbox"
													name="permission"
													style="margin-top: 5px"
													:value="permission.id"
													:checked="checkPermission(permission.id, selectedRoleId)"
													@change="givePermission(permission.id, selectedRoleId)"
												/>
												<div class="ml-1">{{ permission.name }}</div>
											</div>
										</div>
									</div>
								</b-card>
							</b-tab>
						</b-tabs>
					</app-collapse-item>
				</app-collapse>
			</div>
			<div v-else class="d-flex flex-column align-items-center py-5">
				<b-img :src="require('@/assets/images/svg/infographics/no_data_infographic.svg')" class="mr-2" />
				<h4 class="mt-2 Secondary">No modules found</h4>
			</div>
		</b-card>
	</div>
</template>

<script>
import vSelect from "vue-select";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import {
	BCard,
	BRow,
	BImg,
	BCol,
	BFormInput,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
	BTooltip,
	BTabs,
	BTab,
	BCardText,
	BFormDatepicker,
	BFormSelect,
	BFormCheckbox,
	BFormGroup,
} from "bootstrap-vue";

import PermissionList from "./PermissionList.vue";
import { methods } from "vue-echarts";
import axiosIns from "@/libs/axios";

export default {
	components: {
		BCard,
		BFormCheckbox,
		BRow,
		BCol,
		BImg,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		BTooltip,
		BCardText,
		BTabs,
		BTab,

		BFormDatepicker,
		BFormSelect,
		AppCollapse,
		AppCollapseItem,
		PermissionList,
		BFormGroup,
	},
	data() {
		return {
			collapseType: "default",
			allModules: {},
			roles: [],
			// company: {},
			// selected: null,
			// checked: false,
			// role_id: null,
			// permission_id: null,
			allPermissions: null,
			modules_id: null,
			selectedRoleTabIndex: null,
		};
	},
	created: function () {
		this.getAssignedPermission();
		this.getCompanyModules();
	},
	methods: {
		getCompanyModules() {
			axiosIns
				.get(`web/modules`)
				.then((response) => {
					this.allModules = response.data.module.modules;
					this.roles = response.data.module.roles;
					// this.allPermissions = response.data.allPermissions;
				})
				.catch((error) => {
					const data = error.response.data.message;
					data, "data";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data,
						},
					});
				});
		},

		givePermission(permission_id, role_id) {
			var data = new FormData();
			data.append("permission_id", permission_id);
			data.append("role_id", role_id);
			axiosIns
				.post(`web/permissions`, data)
				.then((res) => {
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "success",
							text: `Data Successfully Added `,
						},
					});
					this.checkPermission(permission_id, role_id);
				})
				.catch((error) => {
					const data = error.response.data.errors;
					data, "data";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data,
						},
					});
				});
		},

		getAssignedPermission() {
			let url = `web/module/permissions`;
			axiosIns
				.get(this.addQueryString(url))
				.then((response) => {
					this.assigned_permissions = response.data.rolePermissions;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		checkPermission(permission_id, role_id) {
			if (role_id != null && permission_id != null) {
				let assignedPermission = this.assigned_permissions;
				let permission = assignedPermission.find((element) => {
					return element.permission_id == permission_id && element.role_id == role_id;
				});

				if (permission) {
					return true;
				} else {
					return false;
				}
				// }
			}
		},
	},
	computed: {
		selectedRoleId: function () {
			if (this.selectedRoleTabIndex != null) {
				return this.roles[this.selectedRoleTabIndex].id;
			}
			return null;
		},
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
.header-title {
	height: 60px;
	border-bottom: 1px solid #ebe9f1;
	font-weight: 500;
	color: #636363;
	font-size: 1.714rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem;
}

.invoice-filter-select {
	min-width: 190px;

	::v-deep .vs__selected-options {
		flex-wrap: nowrap;
	}

	::v-deep .vs__selected {
		width: 100px;
	}
}
.permission-view {
	height: 300px;
	overflow: auto;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/pages/nav-role.scss";
</style>
